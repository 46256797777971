.passion {
  padding-top: 110px;
  position: relative;
  min-height: 100%;
}
.passion-heading {
  position: absolute;
  overflow: hidden;
  left: 10px;
  width: 100%;
  font-size: 120px;
  line-height: 120px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: left;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0.05;
}

.passion-section-heading {
  color: white;
  font-weight: bold;
  font-size: 35px;
  margin-top: 80px;
  padding-left: 10px;
}

.tx-primary {
  color: #5ac24d;
}
ul {
  list-style: none;
  text-align: right;
}
.passion-hireme {
  color: white;
  font-weight: 500;
  cursor: pointer;
  border: none;
}
.passion-hireme:hover {
  color: #5ac24d;
}

/* cards */

.passion-card-container {
  padding: 10px 10px 10px 10px;
}
.passion-card-heading {
  color: white !important;
  padding-top: 25px;
  padding-bottom: 10px;
  font-weight: 700;
  font-size: 22px;
  text-align: left;
}
.card-1,
.card-2,
.card-3 {
  padding-bottom: 20px;
}
.passion-card-1,
.passion-card-2,
.passion-card-3 {
  background-color: #2f3234 !important;
  border-radius: 5%;
  height: 100%;
  text-align: left;
  font-size: 18px;
  border-radius: 20px !important;
  box-shadow: 0 20px 25px rgb(25, 25, 26);
}

.pas-card-text {
  margin: 20px 25px 20px 25px;
}

@media (max-width: 600px) {
  .passion-heading {
    font-size: 60px;
    padding-top: 10px;
  }
}
