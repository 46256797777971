/** @format */

.os {
	padding-top: 150px;
	position: relative;
}
.os-heading {
	position: absolute;
	overflow: hidden;
	left: 10px;
	width: 100%;
	font-size: 120px;
	line-height: 120px;
	color: #fff;
	text-transform: uppercase;
	font-weight: 700;
	letter-spacing: 0.04em;
	text-align: left;
	white-space: nowrap;
	pointer-events: none;
	opacity: 0.05;
}

.os-section-heading {
	color: white;
	font-weight: bold;
	font-size: 35px;
	padding-top: 80px;
	padding-left: 10px;
}
.os-section-intro {
	padding-bottom: 40px;
	padding-left: 10px;
}
.tx-primary {
	color: #5ac24d;
}

/* button */
.os-collabrate {
	font-size: 20px;
	color: white;
	font-weight: 400;
	cursor: pointer;
	border: none;
}

.os-collabrate:hover {
	color: #5ac24d;
}
/* cards */

.os-card-heading {
	color: white !important;
	padding-top: 25px;
	font-weight: bold;
	text-align: left;
}
.os-col {
	padding-bottom: 15px;
}
.os-card-1,
.os-card-2,
.os-card-3 {
	background-color: #2f3234 !important;
	color: #a9a9a9 !important;
	border-radius: 5% !important;
	/* margin-right: 25px; */
	display: flex;
	height: 100%;
	text-align: center;
	flex-direction: column;
	margin-bottom: 10px;
	text-align: left;
	letter-spacing: 1px;
	box-shadow: 0 20px 25px rgb(25, 25, 26);
}

.os-card-text {
	font-size: 18px;
}
.os-card-container a {
	color: #5ac24d;
	padding-top: 15px;
	font-size: 17px;
	line-height: 1.3;
}
.os-card-container a:hover {
	color: white;
}
.os-projects-text {
	margin: 18px;
}

.live-demo {
	margin-left: 30px;
	color: orange !important;
}

@media (max-width: 766px) {
	.os-heading {
		font-size: 60px;
		padding-top: 10px;
	}
}
