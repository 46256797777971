.footer {
  color: #fff;
  margin-top: 67px;
}

.footer h3 {
  padding-top: 80px;
  text-align: center;
  font-weight: 200;
  line-height: 60px;
  font-weight: 200;
  font-size: 42px;
}

.footer-text-2 {
  margin-left: 25px;
}

.signature {
  text-align: center;
  margin: 150px 0 120px 0;
}

/* Copyright */

.copyright-logo-container {
  position: relative;
  width: 100%;
  background-color: #333333;
}

.copyright-logo {
  font-size: 16px;
  color: white;
  bottom: 0;
  right: 0;
  position: absolute;
}
