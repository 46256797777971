.hero-primarytx {
  font-size: 40px;
  padding-top: 200px;
  font-weight: 700px;
  color: white;
}

.hero-tx-primary {
  color: #5ac24d;
}

/* .myImg > * {
  background-color: transparent;

  position: fixed;
  z-index: 100;

  left: 10px;
  width: 40px;
}
.myImg-img {
  top: 11vh;
  left: 0;
  border-radius: 0 20px 20px 0;
  z-index: 100;
  width: 20vh;
} */

@media (max-width: 1022px) {
  .myImg-img {
    position: absolute;
    z-index: 0;
  }
}
