.expertise-section {
  position: relative;
  padding-top: 120px;
  padding-bottom: 80px;
}

.expertise-heading {
  position: absolute;
  overflow: hidden;
  width: 100%;
  left: 10px;
  font-size: 120px;
  line-height: 120px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.04em;
  text-align: left;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0.05;
}

.expertise-section-heading {
  color: white;
  font-weight: bold;
  font-size: 35px;
  padding-top: 80px;
  padding-left: 10px;
}
.expertise-section-intro {
  padding-left: 11px;
}
a {
  text-decoration: none !important;
  color: #686969;
}

/* Button */
.expertise-download-resume {
  font-size: 20px;
  color: white;
  font-weight: 400;
  cursor: pointer;
  border: none;
}

.expertise-download-resume:hover {
  color: #5ac24d;
}

/* Cards */

.expertise-card-1,
.expertise-card-2 {
  background-color: #2f3234;
  font-size: 20px;
  margin-bottom: 20px;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0 20px 25px rgb(25, 25, 26);
}
.expertise-card-2 {
  padding-top: 10px;
}
.exp-card-title {
  color: #616264;
  font-size: 20px;
}
.exp-job-title {
  color: white;
  font-size: 20px;
  font-weight: bold;
}
.expertise-job-link {
  font-size: 13px;
  display: flex;
  flex-direction: row;
}
.indishine-container {
  pointer-events: none;
  cursor: default;
}

.expertise-job-link a {
  color: #5ac24d;
}
.exp-job-container {
  padding: 20px;
}
.exp-job-detail {
  text-align: left;
  font-size: 17px;
  padding-top: 5px;
}
/* SKILLS */
/* .skill-container {
  display: flex;
  align-items: center;
  justify-content: center;
} */
.skill-cards {
  background-color: #2f3234 !important;
  height: 120px;
  align-items: center;
  border-radius: 20px;
  width: 160px;
  margin-left: 10px;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: #686969;
  box-shadow: 0 20px 25px rgb(25, 25, 26);
}

.skill-icon {
  padding-top: 20px;
  padding-bottom: 10px;
}
.skill-name {
  font-size: 17px;
  color: white;
}

@media (max-width: 766px) {
  .expertise-heading {
    font-size: 60px;
    padding-top: 10px;
  }
}
