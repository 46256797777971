* {
  margin: 0px;
  padding: 0px;
  border: 0;
  height: auto;
}

.App {
  background-color: #212325 !important;
  background: linear-gradient(135deg, #1d1e21, #3e4246);
  font-size: 24px;

  color: #a9a9a9;
  font-family: "Poppins", sans-serif;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #202425;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #5ac24e;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
