.summary {
  padding-top: 150px;
  position: relative;
}

.summary-heading {
  position: absolute;
  overflow: hidden;
  top: -63px;
  left: 10px;
  width: 100%;
  font-weight: bolder;
  font-size: 120px;
  line-height: 120px;
  color: #fff;
  text-transform: uppercase;

  letter-spacing: 0.04em;
  text-align: left;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0.05;
}

.summary b {
  color: #5ac24d;
  font-weight: 100;
}
.summary p {
  padding-top: 19px;
}

/* button */
.buttons {
  padding-top: 20px;
}
.download-cv {
  background-color: #303436;
  color: white;
  text-decoration: none;
  padding-right: 25px;
  padding: 10px 20px 10px 20px;
  margin-top: 10px;
  border-radius: 40px;
  font-size: 18px;
  text-align: center;
  display: inline;
}
.download-cv i {
  margin-right: 6px;
}
.summary-linkedin-btn,
.summary-skype-btn {
  border-radius: 50%;
  margin-left: 20px;
  width: 50px;
  height: 50px;
  background-color: #3c4043;
}
.download-cv:hover {
  background-color: #5ac24d;
  text-decoration: none;
  color: black;
}
.summary-skype-btn:hover {
  background-color: #5ac24d;
}
.summary-linkedin-btn:hover {
  background-color: #5ac24d;
}

.fa-linkedin-in,
.fa-skype {
  font-size: 30px;
  color: white;

  vertical-align: middle;
  text-align: center;
}

@media (max-width: 600px) {
  .summary-heading {
    font-size: 60px;
  }
  p {
    font-size: 19px;
  }
}
