.contact {
  position: relative;
  margin-top: 130px;
}
.contact-heading {
  position: absolute;
  overflow: hidden;
  left: 10px;
  width: 100%;
  font-size: 120px;
  line-height: 120px;
  font-weight: bold;
  text-transform: uppercase;
  font-weight: 700 !important;
  letter-spacing: 0.04em;
  text-align: left;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0.05;
  color: white;
}
.contact-section-intro {
  padding-left: 28px;
  color: white;
  padding-top: 45px;
  font-weight: bold;
  font-size: 35px;
  margin-top: 30px;
  padding-left: 28px;
}
/* Cards */
.contact a:hover {
  color: white;
}
.contact-card-container {
  padding-top: 15px;
}
.contact-cards {
  padding-top: 30px;
}
.contact-grid {
  background-color: #404347;
  border-radius: 20px;
  box-shadow: 0 20px 25px rgb(25, 25, 26);
}
.contact-grid li {
  text-decoration: none;
  list-style-type: none;
  position: relative;
  width: auto;
  padding: 25px 20px 10px 75px;
  text-align: left;
}
.contact-card-icon {
  margin: 0;
  position: absolute;
  left: 20px;
  top: 20px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 40px;
  color: #5ac24e;
}
.contact-card-title {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  color: #fff;
  font-weight: 700;
}
.contact-grid p {
  margin: 0;
  color: #a9a9a9;
  font-weight: 400;
  font-size: 16px;
}
.email-button,
.call-button {
  position: absolute;
  font-size: 15px;
  right: 10px;
  top: -25px;
  background-color: #5ac24e;
  color: #fff;
  padding: 3px 10px;
  height: 25px;
  border-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  transition-duration: 0.2s;
}
.fa-arrow-right {
  padding-left: 8px;
}
.contact-email {
  padding-right: 10px;
}


/* Seprator */

.contact-seprator {
  padding-top: 40px;
  padding-bottom: 30px;
  margin-left: 20px;
  display: flex;
}

.or-seprator {
  padding-right: 20px;
}

.seprator {
  background-color: transparent !important;
  display: inline-block;
  width: 50%;
  height: 10px;

  margin-top: 17px;
  border-top: 4px solid hsla(0, 0%, 100%, 0.05);
}

/* Form Styling */

.form-container{
padding-top: 60px;
padding-left: 20px;
width: 100%;
box-shadow: 0 20px 25px #19191a;
border-radius: 20px;

}


.form-name-field, .form-email-field,.form-text-field {

border: none;
color: white !important;
background-color: transparent !important;
border-bottom: 1px solid hsla(0,0%,100%,.05);


}

.form-submit-btn{
    display: inline-block;
    font-weight: 400;
    color: white;
    text-align: center;
    background-color: transparent;
    padding: 1rem 1rem;
    font-size: 20px;  
 
}

.form-submit-btn:hover{
  color: #5ac24e;
}

.form-submit-complete{
color:#5ac24e;
align-items: center;
padding-left: 20px;
padding-right: 10px;
margin-top: 16px;
font-size: 20px;
float: right !important;
border: solid  #5ac24e 1px;

}


/* Lets talk */

.live-chat-card {
  background-color: #404347;
  border-radius: 20px;
  width: 200px;
  height: 170px;
  align-items: center;
  box-shadow: 0 20px 25px rgb(25, 25, 26);
}

.fa-comments {
  color: #5ac24e;
  margin-top: 25px;
  padding-bottom: 10px;
  margin-left: 10px;
}
.live-chat {
  color: white;
  text-align: center;
}

.live-chat-container {
  margin: 5px 0 0 70px;
}

@media (max-width: 766px) {
  .contact-heading {
    font-size: 60px;
    padding-top: 10px;
  }
}
