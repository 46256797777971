.subscribe {
  margin-top: 120px;
  margin-bottom: 80px;
  padding: 50px 50px;
  background: linear-gradient(90deg, #434b9d, #4759d8);
  border-radius: 20px;
  box-shadow: grey;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}
.subscribe h5 {
  color: #fff;
  font-size: 30px;
  padding-right: 10px;
  margin-top: 20px;
}
.subscribe input {
  background-color: #434b9d;
  box-shadow: 10px 10px 30px rgb(68 79 173 / 35%);
  border: none;
  padding: 3px 2px;

  border-radius: 20px;
  color: #fff;
  width: 90%;
  margin-top: 25px;
  outline: 0;
}
.small {
  font-size: 15px !important;
  padding-left: 10px;
}

.sub-btn-holder {
  display: block;
  width: 100%;
  margin: 25px 0px 0px 0px;

  font-size: 18px;
  text-transform: uppercase;
  border-radius: 20px;
  background-color: #434b9d;
  border-style: none;
}

.sub-btn-holder Button {
  background-color: #434b9d !important;
  box-shadow: 10px 10px 30px rgb(68 79 173 / 35%);
  border: none;

  border-radius: 10px;
  color: #fff;
  width: 100%;
  margin-top: 20px;
  outline: 0;
}
